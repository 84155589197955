
import { Component, Vue, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import InfoSide from '@/components/InfoSide.vue';
import { required, email} from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { auth} from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import Loader from '@/components/utilities/Loader.vue';
import store from '@/store';
import { showToast } from '@/utils/global-functions';

@Component({
    components: {
        SvgIcon,
        InfoSide,
        Loader
    },
    mixins: [validationMixin],
    validations: {
        email_user:{required, email},
    }
})
export default class SendEmail extends Vue {

    email_user: string = '';
    $refs: any = {}
    registerButton = {
        name: 'Iniciar sesión',
        text: '¿Recordaste tu Contraseña?',
        level: 1,
        redirect: '/inicio-sesion',
    };

    created(){
        this.$socket.client.connect();
    }

    async submit(){
        this.$v.$touch();
        if (!this.$v.$invalid){
            this.$refs.loader.showLoader();
            let response = await this.sendEmail({email_user: this.$v.email_user.$model, type: 'recoverPassword'});
            this.$refs.loader.hideLoader();
            if (response.error){
                showToast(response.msg,'error')
            }
            else {
                store.state.auth.user.email_user = this.$v.email_user.$model;
                this.$router.push({name: 'SendEmailSuccess'}).catch();
            }
        }
    }

    @auth.Action(AuthMethods.actions.SEND_EMAIL)
        sendEmail!: (data:{email_user: string, type: string}) => Promise<{error: boolean, msg: string}>
   
}
